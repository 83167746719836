import { AnalyticsBrowser, type UserTraits } from "@segment/analytics-next";
import pick from "lodash/pick";

import type { UserModelInterface } from "utils/types/account";

export function analytics(): AnalyticsBrowser | undefined {
  if (
    process.env.BUILD_ENV === "development" ||
    process.env.BUILD_ENV === "production"
  ) {
    const analyticsBrowser = AnalyticsBrowser.load({
      writeKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    });

    return analyticsBrowser;
  }

  return undefined;
}

/**
 * Tracks an event with properties to the analytics service.
 * @param event - The name of the event to track.
 * @param properties - The properties to send with the event.
 */
export function trackEvent(
  event: string,
  properties?: Record<string, unknown>
) {
  const a = analytics();
  if (!a) return;

  a.track(event, properties);
}

/**
 * These are the base user traits to identify the user
 */
const BASE_USER_TRAITS_TO_IDENTIFY = [
  "email",
  "firstName",
  "lastName",
  "username",
  "authProvider",
  "subscription",
  "createdAt",
];

/**
 * Identifies a user and sends user traits to the analytics service.
 * @param userId - The ID of the user.
 * @param data - The user data including traits and subscription information.
 */
export async function identifyUser(
  userId: string,
  data?: Partial<UserModelInterface & { subscription: string }>
) {
  const a = analytics();
  if (!a) return;

  const traits: UserTraits = {
    ...pick(data, BASE_USER_TRAITS_TO_IDENTIFY),
  };

  if (data.customerTeams?.[0]) {
    traits.team = data.customerTeams[0].teamName;
  }

  a.identify(userId, traits);
}

export async function softIdentifyUser(
  data?: Partial<{ email: string; firstName: string; lastName: string }>
) {
  const a = analytics();
  if (!a) return;

  a.identify(data);
}

export function resetAnalytics() {
  const a = analytics();
  if (!a) return;

  a.reset();
}

export function pageViewed(path: string) {
  const a = analytics();
  if (!a) return;

  a.page({ path });
}
